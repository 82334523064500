<template>
  <div class="container" v-if="user">
    <div class="page-header">
      <div class="page-header__ell">
        <h1 class="page-title">Редактировать администратора</h1>
      </div>
      <!--
      <div class="page-header__ell">
        <b-form-checkbox v-model="checked" name="check-button" size="lg" switch>
          {{checked ? 'Отключить':'Включить'}}
        </b-form-checkbox>
      </div>
      -->
    </div>
    <ManagerEditForm
      :formData="user"
      ref="userForm"
      :editForm="true"
    />
    <div class="btn-gp">
      <b-button size="lg" variant="danger" @click="save">Сохранить</b-button>
      <router-link :to="{name: this.$routeName.MANAGER_LIST}" class="btn btn-link btn-lg">Отмена</router-link>
    </div>
  </div>
</template>

<script>

import ManagerEditForm from '@component/Form/ManagerEditForm';

export default {
  name: "ManagerEdit",
  components: {
    ManagerEditForm,
  },
  props: {
    managerId: [String, Number]
  },
  data(){
    return {
      user: false,
      checked: false,
    };
  },
  methods:{
    save() {
      let formData = this.$refs.userForm.formSubmitGetData();
      if(!formData) { return; }

      let sendData = Object.assign({}, formData);
      sendData.clubs = JSON.stringify( sendData.clubList.map((item) => item.id) );
      delete sendData.clubList;

      if(sendData.access === 'system') {
        sendData.is_superuser = true;
      } else {
        sendData.is_superuser = false;
      }
      delete sendData.access;

      delete sendData.avatar;

      RequestManager.Manager.updateManagerById({
        id    : this.managerId,
        user  : sendData
      }).then( (res) => {
        this.$toasts.push({
          message: 'Администратор успешно обновлен',
          duration: 4000,
        });
        this.$router.push({name: this.$routeName.MANAGER_LIST });
      });

    },
  },
  mounted() {
    RequestManager.Manager.getManagerById({
      id: this.managerId
    }).then((user) => {
      this.user = user;
      // fix
      if(this.user.is_superuser) {
        this.user.access = 'system';
      } else {
        this.user.access = 'club';
      }
      this.user.clubList = this.user.clubs;
    });
  }
};
</script>

<style lang="scss" scoped>

</style>
